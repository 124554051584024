<template>
    <a-modal :width="800" :visible="serviceInfoModalVisible" title="" :footer="null" @cancel="closeModal">
        <div v-html="serviceInfo"></div>
    </a-modal>
</template>

<script>
export default {
    props: ["serviceInfoModalVisible", "closeServiceInfoModal", "serviceInfo"],
    methods: {
        closeModal() {
            this.closeServiceInfoModal();
        },
    },
};
</script>
