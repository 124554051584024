<template>
	<div class="registerContainer">
		<div class="register">
			<a-row class="titleRow">
				<span class="title">
					{{ title }}
					<img :src="logoUrl" />
				</span>
			</a-row>
			<a-row class="welcome">欢迎注册{{ title }} 开启您的广告智能检测之旅</a-row>
			<a-row class="registerText">注册</a-row>
			<a-form-model ref="loginForm" :model="form" :rules="rules">
				<a-form-model-item prop="loginName" :whitespace="true">
					<a-input class="titleInput" v-model="form.loginName" placeholder="请输入您的用户名"></a-input>
				</a-form-model-item>
				<a-form-model-item prop="region" :whitespace="true">
					<a-cascader :options="options" v-model="form.region" placeholder="请选择您的所在地"></a-cascader>
				</a-form-model-item>
				<a-form-model-item prop="userPhone" :whitespace="true">
					<a-input class="formInput" v-model="form.userPhone" placeholder="请输入您的手机号"></a-input>
				</a-form-model-item>
				<a-form-model-item prop="loginPass" :whitespace="true">
					<a-input-password class="formInput" v-model="form.loginPass" placeholder="请输入您的密码"></a-input-password>
				</a-form-model-item>
				<a-form-model-item prop="loginPass2" :whitespace="true">
					<a-input-password class="formInput" v-model="form.loginPass2" placeholder="请确认您的密码"></a-input-password>
				</a-form-model-item>
				<a-form-model-item prop="userPhoneSms" :whitespace="true">
					<a-input-group compact>
						<a-input class="formInput userPhoneSmsInput" v-model="form.userPhoneSms" placeholder="请输入您的验证码"></a-input>
						<a-button type="primary" class="getCode" :disabled="getSmscodeFlag" @click="getSmsCode">{{ smsCount }}{{ getSmscodeFlag ? '秒' : '' }}</a-button>
					</a-input-group>
				</a-form-model-item>
			</a-form-model>
			<!-- <a-input class="formInput userPhoneInput" ref="userPhoneInput" v-model="loginName" placeholder="请输入您的用户名"> </a-input> -->
			<!-- <a-input class="formInput userPhoneInput" ref="userPhoneInput" v-model="userPhone" placeholder="请输入您的手机号"> </a-input> -->
			<!-- <a-input-password class="formInput" ref="userPhoneInput" v-model="loginPass" placeholder="请输入您的密码"> </a-input-password>
            <a-input-password class="formInput" ref="userPhoneInput2" v-model="loginPass2" placeholder="请确认您的密码"> </a-input-password> -->

			<!-- <a-input-group compact>
                <a-input class="formInput userPhoneSmsInput" ref="userPhoneSmsInput" v-model="userPhoneSms" placeholder="请输入您的验证码"> </a-input>
                <a-button type="primary" class="getCode" :disabled="getSmscodeFlag" @click="getSmsCode">{{ smsCount }}{{ getSmscodeFlag ? "秒" : "" }}</a-button>
            </a-input-group> -->
			<a-checkbox @change="onChange" class="checkBox"></a-checkbox>
			<span class="checkBoxText">
				我已阅读并接受
				<a @click="queryInfo">《智能广告智能检测服务条款》</a>
			</span>
			<a-row>
				<a-button type="primary" class="submit" :disabled="!checked" @click="submit" :loading="loading">注册</a-button>
			</a-row>
			<a-row class="footerText">
				<a-col :span="12">
					<a class="footer" @click="back()">已有账号，立即登录</a>
				</a-col>
				<a-col :span="12">
					<a class="footer floatR" @click="forgetPassword()">忘记密码</a>
				</a-col>
			</a-row>
		</div>
		<ServiceInfoModal :serviceInfoModalVisible="serviceInfoModalVisible" :closeServiceInfoModal="closeServiceInfoModal" :serviceInfo="serviceInfo" />
	</div>
</template>

<script>
import ServiceInfoModal from './components/ServiceInfoModal';
import options from '../../utils/common/cascader-address-options';
options.map(provinceItem => {
	provinceItem.value = provinceItem.label;
	provinceItem.children.map(cityItem => {
		cityItem.value = cityItem.label;
		cityItem.children.map(districtItem => {
			districtItem.value = districtItem.label;
		});
	});
});
export default {
	components: { ServiceInfoModal },
	data() {
		const _this = this;
		let checkName = (rule, value, callback) => {
			if (value && value != '') {
				if (value.length < 2 || value.length > 8) {
					callback('请输入2到8位用户名！');
				} else {
					callback();
				}
			} else {
				callback(new Error('请输入用户名称'));
			}
		};
		let chekckLoginPass = (rule, value, callback) => {
			if (value && value != '') {
				let reg = new RegExp(/^.*(?=.{8,20})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/);
				if (!reg.test(value)) {
					callback('请输入8至20个字符并包含数字、大小写字母组合的密码！');
				} else {
					callback();
				}
			} else {
				callback(new Error('请输入密码'));
			}
		};
		let chekckConfirmPassword = (rule, value, callback) => {
			if (value && value != '') {
				if (value != _this.form.loginPass) {
					callback('两次密码不一致，请重新输入');
				} else {
					callback();
				}
			} else {
				callback(new Error('请输入密码'));
			}
		};
		let chekckPhone = (rule, value, callback) => {
			if (value && value != '') {
				let reg = new RegExp(/^1[3456789]\d{9}$/);
				if (!reg.test(value)) {
					callback('请输入正确的手机号码');
					return;
				}
				let params = {
					field: 'phone',
					value: value,
				};
				_this.$http
					.post('/userbasic/uniqueValidatorByConsumer', params)
					.then(res => {
						if (!res.data) {
							callback(new Error('该手机已被使用，请重新输入'));
							return;
						}
						callback();
					})
					.catch(err => {
						// callback(new Error("该手机已被使用，请重新输入"));
					});
			} else {
				callback(new Error('请输入手机号码'));
			}
		};
		return {
			title: '智广宝-鸿星智能广告服务平台',
			logoUrl: 'https://resources.xbov.cn/img/C_logo.jpg',
			options: options,
			form: {
				userPhone: null,
				userPhoneSms: null,
				loginName: null,
				loginPass: null,
				loginPass2: null,
				region: [],
			},
			checked: false,
			loading: false,
			smsCount: '获取验证码',
			getSmscodeFlag: false,
			rules: {
				region: [{ required: true, message: '请选择您的所在地', trigger: 'change' }],
				loginName: [{ required: true, validator: checkName, trigger: 'change' }],
				loginPass: [{ required: true, validator: chekckLoginPass, trigger: 'change' }],
				loginPass2: [{ required: true, validator: chekckConfirmPassword, trigger: 'change' }],
				userPhone: [{ required: true, validator: chekckPhone, trigger: 'change' }],
			},
			serviceInfoModalVisible: false,
			serviceInfo: '',
		};
	},
	created() {
		let domainInfo = this.$store.getters.domainInfo;
		if (domainInfo.domainName) {
			this.title = domainInfo.title;
			this.logoUrl = process.env.VUE_APP_RESOURCES_HOST + domainInfo.logoUrl;
		}
	},
	methods: {
		closeServiceInfoModal() {
			this.serviceInfoModalVisible = false;
			this.serviceInfo = '';
		},
		queryInfo() {
			let params = {
				configKey: 'serviceContent',
			};
			const _this = this;
			this.$http.post('/systemsetting/queryList', params).then(res => {
				_this.serviceInfoModalVisible = true;
				_this.serviceInfo = res.data.dataList[0].configValue;
			});
		},
		onChange(e) {
			this.checked = e.target.checked;
		},
		back() {
			this.$router.go(-1);
		},
		forgetPassword() {
			this.$router.push({
				name: 'ForgetPassword',
				query: {},
			});
		},
		getSmsCode() {
			const _this = this;
			_this.$refs.loginForm.validateField('userPhone', err => {
				if (err == '') {
					_this.$http.get('/login/smsSendCode?phone=' + _this.form.userPhone + '&type=1').then(res => {
						_this.getSmscodeFlag = true;
						_this.smsCount = 60;
						let timer = setInterval(() => {
							_this.smsCount -= 1;
							if (_this.smsCount == 0) {
								clearInterval(timer);
								_this.smsCount = '获取验证码';
								_this.getSmscodeFlag = false;
							}
						}, 1000);
					});
				}
			});
		},
		submit() {
			const _this = this;
			this.$refs.loginForm.validate(valid => {
				if (valid) {
					if (!_this.form.userPhoneSms || _this.form.userPhoneSms == '') {
						_this.$message.warning('请先输入验证码');
						return;
					}
					if (_this.form.userPhoneSms.length != 6) {
						_this.$message.warning('请先输入6位数的验证码');
						return;
					}
					if (!_this.$CommonUtil.numTest(_this.form.userPhoneSms)) {
						_this.$message.warning('验证码错误!');
						return;
					}
					_this.loading = true;
					let params = {
						name: this.form.loginName,
						phone: this.form.userPhone,
						smsCode: this.form.userPhoneSms,
						token: {
							// loginName: this.form.userPhone,
							loginPassword: this.form.loginPass,
						},
						userType: 1,
						province: this.form.region[0],
						city: this.form.region[1],
						district: this.form.region[2],
						checkFlag: 2, // 需要校验手机验证码
					};
					this.$http
						.post('/userbasic/register', params)
						.then(res => {
							if (res.rescode == 200) {
								//注册成功
								_this.$message.success('注册成功');
								_this.loading = false;
								_this.$router.push({
									name: 'Login',
									query: {},
								});
							} else {
								_this.$message.warning(res.msg);
								_this.loading = false;
							}
						})
						.catch(error => {
							_this.loading = false;
							console.log(error);
						});
				}
			});
		},
	},
};
</script>

<style lang="less" scoped>
.registerContainer {
	background: url('https://resources.xbov.cn/img/register_bgc.png') no-repeat;
	background-size: 100% 100%;
	position: relative;
	.register {
		position: absolute;
		right: 170px;
		top: 100px;
		width: 580px;
		height: 750px;
		box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.12);
		padding: 50px;
		.titleRow {
			text-align: center;
			margin-top: 10px;
			.title {
				font-size: 27px;
				font-weight: 700;
				margin-left: 60px;
				position: relative;
				img {
					position: absolute;
					top: -5px;
					left: -60px;
					width: 50px;
					height: 50px;
				}
			}
		}
		.welcome {
			text-align: center;
			color: #999999;
			margin-top: 30px;
			font-size: 18px;
		}
		.registerText {
			font-size: 20px;
			font-weight: 700;
			margin-top: 40px;
		}
		.formInput {
			height: 40px;
		}
		.titleInput {
			height: 40px;
			margin-top: 20px;
		}
		.userPhoneSmsInput {
			width: 70%;
		}
		.getCode {
			height: 40px;
			margin-left: 20px;
			width: 26%;
			border-radius: 3px;
		}
		.checkBox {
			margin-top: 20px;
		}
		.checkBoxText {
			margin-left: 10px;
		}
		.submit {
			width: 100%;
			height: 50px;
			margin-top: 20px;
			font-size: 20px;
		}
		.footerText {
			margin-top: 20px;
			.footer {
				font-size: 18px;
			}
			.floatR {
				float: right;
			}
		}
	}
}
</style>
